export const BSC = 56
export const ARBITRUM = 42161
export const AVALANCHE = 43114
export const FANTOM_TESTNET = 4002
export const BASE = 8453
export const BASE_TESTNET = 84531

export const addresses = {
    [BSC]: {
        Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
        Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
        USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
        Stabilize: "0x82C4841728fBd5e08A77A95cA3192BcE1F645Ee9",
        WardenSwapRouter: "0x7A1Decf6c24232060F4D76A33a317157549C2093",
        OneInchRouter: "0x11111112542D85B3EF69AE05771c2dCCff4fAa26",
        DodoexRouter: "0x8F8Dd7DB1bDA5eD3da8C9daf3bfa471c12d58486",
        MetamaskRouter: "0x1a1ec25DC08e98e5E93F1104B5e5cdD298707d31"
    },

    [ARBITRUM]: {
        GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        BTC: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        ETH: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        RewardReader: '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
        GLP: '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
        GlpManager: '0x321F653eED006AD1C29D174e17d96351BDe22649'
    },

    [AVALANCHE]: {
        GMX: '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
        AVAX: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        ETH: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
        BTC: '0x50b7545627a5162f82a992c33b87adc75187b218',
        RewardReader: '0x04Fc11Bd28763872d143637a7c768bD96E44c1b6',
        GLP: '0x01234181085565ed162a948b6a5e88758CD7c7b8',
        GlpManager: '0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F'
    },

    [FANTOM_TESTNET]: {
        GMX: '0x640182C413caDb911399aA4601A6803c6101a7ab',
        // Test fantom we created (ERC20), not not real FTM
        FTM: '0xB6d688F6aFE3f34DBC006259E91b7cfFDcdf3359',
        RewardReader: '0x77d01d9150F19789DCD2056c29532f94DD35cAc4',
        GLP: '0x0EE4bF502597aC8b2324dF3C956A5cB8455C5716',
        GlpManager: '0xcA5dfC8d8718A135CBaE21b3cC933233673D7Ad1'
    },

    [BASE]: {
        GMX: '0x44d05c7Bf593b4cC4322088fFCa35613079E8D37',
        ETH: '0x4200000000000000000000000000000000000006',
        RewardReader: '0x31c426133e35afc6760c4078DbFc0980818d8490',
        GLP: '0x17A9CA5Fb8e1edB970caD49867d5b9299d3D1cA7',
        GlpManager: '0xDA9B1f3C4d7C70582f4Ae1e5B122dEe6cA2D1923'
    },

    [BASE_TESTNET]: {
        GMX: '0x640182C413caDb911399aA4601A6803c6101a7ab',
        // Test ETH we created (ERC20), not not real ETH
        ETH: '0x4E3BF16aE7240dE48D8424b8604fD8EAB4Ffa10C',
        RewardReader: '0x985E799FE6B5271689Dd311AB22D5876f9a811e2',
        GLP: '0x750612B151Ee55fc22FBf07Beb2ad898fb4a34Dd',
        GlpManager: '0x7bEa886F08921ba9683B0e78cCaD6bEBBf205512'
    },
}

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
