import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import Base from './views/Base';
import BaseTestnet from './views/BaseTestnet';
import './App.css';
import logoIcon from './img/voodoo_logo_white_transparent.png';
import darkLogoIcon from './img/voodoo_logo_white_transparent.png';
import { FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { RiMenuLine } from 'react-icons/ri';

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small &&
        <div className="App-header-links-header">
          <div className="App-header-menu-icon-block" onClick={() => clickCloseIcon()}>
            <FiX className="App-header-menu-icon" />
          </div>
          <NavLink exact activeClassName="active" className="App-header-link-main" to="/">
            <img src={logoIcon} alt="VMX Logo" />
          </NavLink>
        </div>
      }
      {/* <div className="App-header-link-container">
        <NavLink to="/" exact className="nav-link" activeClassName="active">Fantom</NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink to="/testnet" exact className="nav-link" activeClassName="active">Fantom Testnet</NavLink>
      </div> */}
    </div>
  )
}

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 }
  }

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }

  useEffect(() => {
    const savedMode = window.localStorage.getItem('mode');
    const targetMode = savedMode == 'light' ? 'light' : 'dark';
    document.querySelector('body').style.backgroundColor = targetMode == 'dark' ? '#101124' : '#f6f9ff';
    setMode(targetMode);
  }, [])

  const switchMode = () => {
    const targetMode = mode == 'dark' ? 'light' : 'dark';
    window.localStorage.setItem('mode', targetMode);
    document.querySelector('body').style.backgroundColor = targetMode == 'dark' ? '#101124' : '#f6f9ff';
    setMode(targetMode)
  }

  return (
    <Switch>
      {
        mode && <div className={cx("App", mode)}>
          {isDrawerVisible &&
            <AnimatePresence>
              {isDrawerVisible &&
                <motion.div className="App-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                >
                </motion.div>
              }
            </AnimatePresence>
          }
          <div className="nav">
            <div className="nav-left">
              <div className="App-header-menu-icon-block" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                {!isDrawerVisible && <RiMenuLine className="App-header-menu-icon" />}
                {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
              </div>
              <a href="/" className="nav-logo">
                <img width="87" src={darkLogoIcon} />
              </a>
              <NavLink to="/" exact className="nav-link" activeClassName="active">Mainnet</NavLink>
              <NavLink to="/testnet" exact className="nav-link" activeClassName="active">Testnet</NavLink>

            </div>
            <div className="nav-right">
              <a href="https://voodoo.trade" target="_blank" className="nav-link">APP</a>
              <a href="https://voodootrade.gitbook.io/voodoo-trade" target="_blank" className="nav-link">DOCS</a>
            </div>
          </div>
          <AnimatePresence>
            {isDrawerVisible &&
              <motion.div
                onClick={() => setIsDrawerVisible(false)}
                className="App-header-links-container App-header-drawer"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={slideVariants}
                transition={{ duration: 0.2 }}
              >
                <AppHeaderLinks mode={mode} small clickCloseIcon={() => setIsDrawerVisible(false)} />
              </motion.div>
            }
          </AnimatePresence>

          <div className="content">
            <Route exact path="/" render={(props) => (
              <Base {...props} mode={mode} />
            )} />
            <Route exact path="/testnet" render={(props) => (
              <BaseTestnet {...props} mode={mode} />
            )} />
            <Route path="/mainnet">
              <Redirect to="/" />
            </Route>
            {/* <Route exact path="/testnet" render={(props) => (
              <FantomTestnet {...props} mode={mode} />
            )} />
            <Route exact path="/referrals/:chainName" render={(props) => (
              <Referrals {...props} mode={mode} />
            )} />
            <Route exact path="/trading" component={Trading} /> */}
          </div>

        </div>
      }
    </Switch>
  )
};

export default App;
