import React, { useEffect, useState, useCallback, useMemo } from 'react';
import * as ethers from 'ethers'
import moment from 'moment'
import { RiLoader5Fill } from 'react-icons/ri'
import cx from "classnames";

import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  formatNumber,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
  GREEN,
  RED,
  convertToPercents
} from '../helpers'

import {
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Cell
} from 'recharts';

import ChartWrapper from '../components/ChartWrapper'
import VolumeChart from '../components/VolumeChart'
import FeesChart from '../components/FeesChart'
import GenericChart from '../components/GenericChart'
import DateRangeSelect from '../components/DateRangeSelect'

import {
  useVolumeData,
  useFeesData,
  useGlpData,
  useGlpPerformanceData,
  useTradersData,
  useSwapSources,
  useFundingRateData,
  useUsersData,
  useLastSubgraphBlock,
  useLastBlock
} from '../dataProvider'
import PoolAmountChart from '../components/PoolAmountChart';
import TradersProfitLossChart from '../components/TradersProfitLossChart';
import DateRangeSelectV2 from '../components/DateRangeSelectV2';

import TotalVolumeImage from '../img/TotalVolumeImage.svg';
import TotalFeesImage from '../img/TotalFeesImage.svg';
import VLPPoolImage from '../img/VLPPoolImage.svg';
import TotalUsersImage from '../img/TotalUsersImage.svg';
import OpenInterestImage from '../img/OpenInterestImage.svg';

const NOW = Math.floor(Date.now() / 1000)

function Base(props) {
  const DEFAULT_GROUP_PERIOD = 86400
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD)
  const [dataRange, setDataRange] = useState({ fromValue: moment().subtract(3, 'month').toDate(), toValue: null })

  const { mode } = props

  const from = dataRange.fromValue ? Math.floor(+new Date(dataRange.fromValue) / 1000) : undefined
  const to = dataRange.toValue ? Math.floor(+new Date(dataRange.toValue) / 1000) : NOW

  const params = { from, to, groupPeriod, chainName: 'base' }

  const [fundingRateData, fundingRateLoading] = useFundingRateData(params)

  const [volumeData, volumeLoading] = useVolumeData(params)
  const [totalVolumeData, totalVolumeLoading] = useVolumeData({ chainName: 'base' })

  const [totalVolume, totalVolumeDelta] = useMemo(() => {
    if (!totalVolumeData) {
      return []
    }
    const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative
    const delta = total - totalVolumeData[totalVolumeData.length - 2]?.cumulative
    return [total, delta]
  }, [totalVolumeData])

  const [feesData, feesLoading] = useFeesData(params)
  const [totalFeesData, totalFeesLoading] = useFeesData({ chainName: 'base' })
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return []
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative
    const delta = total - totalFeesData[totalFeesData.length - 2]?.cumulative
    return [total, delta]
  }, [totalFeesData])

  const [glpData, glpLoading] = useGlpData(params)
  const [totalGlpData, totalGlpLoading] = useGlpData({ chainName: 'base' })
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!totalGlpData) {
      return []
    }
    const total = totalGlpData[totalGlpData.length - 1]?.aum
    const delta = total - totalGlpData[totalGlpData.length - 2]?.aum
    return [total, delta]
  }, [totalGlpData])

  // const [aumPerformanceData, aumPerformanceLoading] = useAumPerformanceData(params)
  const [glpPerformanceData, glpPerformanceLoading] = useGlpPerformanceData(glpData, feesData, params)

  const [tradersData, tradersLoading] = useTradersData(params)
  const [totalTradersData, totalTradersLoading] = useTradersData({ chainName: 'base' })
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!totalTradersData) {
      return []
    }
    const total = totalTradersData.data[totalTradersData.data.length - 1]?.openInterest
    const delta = total - totalTradersData.data[totalTradersData.data.length - 2]?.openInterest
    return [total, delta]
  }, [totalTradersData])

  const [usersData, usersLoading] = useUsersData(params)
  const [totalUsersData, totalUsersLoading] = useUsersData({ chainName: 'base' })
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!totalUsersData) {
      return [null, null]
    }
    const total = totalUsersData[totalUsersData.length - 1]?.uniqueCountCumulative
    const prevTotal = totalUsersData[totalUsersData.length - 2]?.uniqueCountCumulative
    const delta = total && prevTotal ? total - prevTotal : null
    return [
      total,
      delta
    ]
  }, [totalUsersData])

  const [swapSources, swapSourcesLoading] = useSwapSources(params)
  const swapSourcesKeys = Object.keys((swapSources || []).reduce((memo, el) => {
    Object.keys(el).forEach(key => {
      if (key === 'all' || key === 'timestamp') return
      memo[key] = true
    })
    return memo
  }, {}))

  const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock(params.chainName)
  const [lastBlock] = useLastBlock(params.chainName)

  const isObsolete = lastSubgraphBlock && lastBlock && lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end })
  };

  const dateRangeOptions = [{
    label: "Last Month",
    id: 1
  }, {
    label: "2 M",
    id: 2,
    isDefault: true,
  }, {
    label: "3 M",
    id: 3,
  }, {
    label: "All time",
    id: 4
  }]

  return (
    <div
      className='FantomTestnet-Wrapper'
    >
      <div className="FantomTestnet">
        <div className="page-title-section">
          <div className="page-title-block">
            <h1>Analytics / Base</h1>
            {lastSubgraphBlock && lastBlock &&
              <p className={cx('page-description', { warning: isObsolete })}>
                {isObsolete && "Data is obsolete. "}
                Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
                &nbsp;at block <a rel="noreferrer" target="_blank" href={`https://basescan.org/block/${lastSubgraphBlock.number}`}>{lastSubgraphBlock.number}</a>
              </p>
            }
            {
              lastSubgraphBlockError &&
              <p className="page-description warning">
                Subgraph data is temporarily unavailable.
              </p>
            }
          </div>
          <div className="form">
            <DateRangeSelectV2 options={dateRangeOptions} startDate={dataRange.fromValue} endDate={dataRange.toValue} onChange={onDateRangeChange} />
            {/* <DateRangeSelect options={dateRangeOptions} startDate={dataRange.fromValue} endDate={dataRange.toValue} onChange={onDateRangeChange} /> */}
          </div>
        </div>
        <div className="chart-flex-column">
        {/* <div className="chart-grid"> */}
          <div
            className='chart-cells-stats-wrapper'
          >
            <div className="chart-cell-flex stats-flex">
              {totalVolume ? <>
                <div
                  className='chart-cell-flex-stat-label-wrapper'
                >
                  <img
                    src={TotalVolumeImage}
                    alt="TotalVolumeImage"
                    className='chart-cell-flex-stat-label-image'
                  />
                  <div className="chart-cell-flex-stat-label">Total Volume</div>
                </div>
                <div className="chart-cell-flex-stat-value">
                  {formatNumber(totalVolume, { currency: true })}
                  {!!totalVolumeDelta &&
                    <span className="total-stat-delta plus" title="Change since previous day">+{formatNumber(totalVolumeDelta, { currency: true, compact: true })}</span>
                  }
                </div>
              </> : null}
              {totalVolumeLoading && <RiLoader5Fill size="3em" className="loader" />}
            </div>
            <div className="chart-cell-flex stats-flex">
              {totalFees ? <>
                <div
                  className='chart-cell-flex-stat-label-wrapper'
                >
                  <img
                    src={TotalFeesImage}
                    alt="TotalFeesImage"
                    className='chart-cell-flex-stat-label-image'
                  />
                  <div className="chart-cell-flex-stat-label">Total Fees</div>
                </div>
                <div className="chart-cell-flex-stat-value">
                  {formatNumber(totalFees, { currency: true })}
                  {!!totalFeesDelta &&
                    <span className="total-stat-delta plus" title="Change since previous day">+{formatNumber(totalFeesDelta, { currency: true, compact: true })}</span>
                  }
                </div>
              </> : null}
              {totalFeesLoading && <RiLoader5Fill size="3em" className="loader" />}
            </div>
            <div className="chart-cell-flex stats-flex">
              {totalAum ? <>
                <div
                  className='chart-cell-flex-stat-label-wrapper'
                >
                  <img
                    src={VLPPoolImage}
                    alt="VLPPoolImage"
                    className='chart-cell-flex-stat-label-image'
                  />
                  <div className="chart-cell-flex-stat-label">VLP Pool</div>
                </div>
                <div className="chart-cell-flex-stat-value">
                  {formatNumber(totalAum, { currency: true })}
                  {/* {!!totalAumDelta &&
                    <span className={cx("total-stat-delta", (totalAumDelta > 0 ? 'plus' : 'minus'))} title="Change since previous day">{totalAumDelta > 0 ? '+' : ''}{formatNumber(totalAumDelta, { currency: true, compact: true })}</span>
                  } */}
                </div>
              </> : null}
              {totalGlpLoading && <RiLoader5Fill size="3em" className="loader" />}
            </div>
            <div className="chart-cell-flex stats-flex">
              {totalUsers && <>
                <div
                  className='chart-cell-flex-stat-label-wrapper'
                >
                  <img
                    src={TotalUsersImage}
                    alt="TotalUsersImage"
                    className='chart-cell-flex-stat-label-image'
                  />
                  <div className="chart-cell-flex-stat-label">Total Users</div>
                </div>
                <div className="chart-cell-flex-stat-value">
                  {formatNumber(totalUsers)}
                  {!!totalUsersDelta &&
                    <span className="total-stat-delta plus" title="Change since previous day">+{formatNumber(totalUsersDelta)}</span>
                  }
                </div>
              </>}
              {totalUsersLoading && <RiLoader5Fill size="3em" className="loader" />}
            </div>
            <div className="chart-cell-flex stats-flex">
              {openInterest ? <>
                <div
                  className='chart-cell-flex-stat-label-wrapper'
                >
                  <img
                    src={OpenInterestImage}
                    alt="OpenInterestImage"
                    className='chart-cell-flex-stat-label-image'
                  />
                  <div className="chart-cell-flex-stat-label">Open Interest</div>
                </div>
                <div className="chart-cell-flex-stat-value">
                  {formatNumber(openInterest, { currency: true })}
                  {!!openInterestDelta &&
                    <span className={cx("total-stat-delta", (openInterestDelta > 0 ? 'plus' : 'minus'))} title="Change since previous day">
                      {openInterestDelta > 0 ? '+' : ''}{formatNumber(openInterestDelta, { currency: true, compact: true })}
                    </span>
                  }
                </div>
              </> : null}
              {totalTradersLoading && <RiLoader5Fill size="3em" className="loader" />}
            </div>
          </div>


          <div
            className='chart-grid-V2'
          >
            <div className="chart-cell-V2">
              <VolumeChart
                data={volumeData}
                loading={volumeLoading}
                chartHeight={CHART_HEIGHT}
                yaxisWidth={YAXIS_WIDTH}
              />
            </div>
            <div className="chart-cell-V2">
              <FeesChart
                data={feesData}
                loading={feesLoading}
                chartHeight={CHART_HEIGHT}
                yaxisWidth={YAXIS_WIDTH}
                xaxisTickFormatter={tooltipLabelFormatter}
                tooltipLabelFormatter={tooltipLabelFormatter}
              />
            </div>
            <div className="chart-cell-V2">
              <ChartWrapper title="AUM & VLP Supply" loading={glpLoading} data={glpData} csvFields={[{ key: 'aum' }, { key: 'glpSupply', name: 'VLP Supply' }]}>
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                  <LineChart data={glpData} syncId="syncGlp">
                    <CartesianGrid strokeDasharray="10 10" />
                    <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                    <YAxis dataKey="glpSupply" tickFormatter={yaxisFormatter} width={YAXIS_WIDTH} />
                    <Tooltip
                      formatter={tooltipFormatterNumber}
                      labelFormatter={tooltipLabelFormatter}
                      contentStyle={{ textAlign: 'left' }}
                    />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      iconType="circle"
                      iconSize={12}
                    />
                    <Line isAnimationActive={false} type="monotone" strokeWidth={2} unit="$" dot={false} dataKey="aum" stackId="a" name="AUM" stroke={COLORS[0]} />
                    <Line isAnimationActive={false} type="monotone" strokeWidth={2} dot={false} dataKey="glpSupply" stackId="a" name="VLP Supply" stroke={COLORS[1]} />
                  </LineChart>
                </ResponsiveContainer>
              </ChartWrapper>
            </div>
            <div className="chart-cell-V2">
              <PoolAmountChart
                from={from}
                to={to}
                chainName={params.chainName}
                syncId="syncGlp"
              />
            </div>

            <div className="chart-cell-V2">
              <ChartWrapper
                title="VLP Performance"
                loading={glpLoading}
                data={glpPerformanceData}
                csvFields={[{key: 'syntheticPrice'}, {key: 'glpPrice', name: 'vlpPrice'}, {key: 'glpPlusFees', name: 'vlpPlusFees'}, {key: 'lpEthPrice'}]}
              >
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                  <LineChart data={glpPerformanceData} syncId="syncGlp">
                    <CartesianGrid strokeDasharray="10 10" />
                    <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                    <YAxis dataKey="performanceLpEthCollectedFees" domain={[60, 120]} unit="%" tickFormatter={yaxisFormatterNumber} width={YAXIS_WIDTH} />
                    <Tooltip
                      formatter={tooltipFormatterNumber}
                      labelFormatter={tooltipLabelFormatter}
                      contentStyle={{ textAlign: 'left' }}
                    />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      iconType="circle"
                      iconSize={12}
                    />
                    <Line dot={false} isAnimationActive={false} type="monotone" unit="%" dataKey="performanceLpFtmCollectedFees" name="% LP ETH-USDC" stroke={COLORS[3]} />
                    <Line dot={false} isAnimationActive={false} type="monotone" unit="%" dataKey="performanceSyntheticCollectedFees" name="% Index" stroke={COLORS[0]} />
                  </LineChart>
                </ResponsiveContainer>
                <div className="chart-description">
                  <p>
                    <span style={{color: COLORS[0]}}>% of Index</span> is VLP with fees / Index Price * 100. Index is a basket of 50% ETH and 50% USDC
                      <br/><br/>
                    <span style={{color: COLORS[4]}}>% of LP TOKEN-USDC</span> is VLP Price with fees / LP TOKEN-USDC * 100<br/>
                  </p>
                </div>
              </ChartWrapper>
            </div>

            <div className="chart-cell-V2">
              <ChartWrapper
                title="VLP Price Comparison"
                loading={glpLoading}
                data={glpPerformanceData}
                csvFields={[{ key: 'syntheticPrice' }, { key: 'glpPrice', name: 'vlpPrice' }, { key: 'glpPlusFees', name: 'vlpPlusFees' }, { key: 'lpEthPrice' }]}
              >
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                  <LineChart data={glpPerformanceData} syncId="syncGlp">
                    <CartesianGrid strokeDasharray="10 10" />
                    <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                    <YAxis dataKey="glpPrice" domain={[0.3, 1.8]} tickFormatter={yaxisFormatterNumber} width={YAXIS_WIDTH} />
                    <Tooltip
                      formatter={tooltipFormatterNumber}
                      labelFormatter={tooltipLabelFormatter}
                      contentStyle={{ textAlign: 'left' }}
                    />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      iconType="circle"
                      iconSize={12}
                    />

                    <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="syntheticPrice" name="Index Price" stroke={COLORS[2]} />
                    <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="glpPrice" name="VLP Price" stroke={COLORS[1]} />
                    <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={2} dot={false} dataKey="glpPlusFees" name="VLP w/ fees" stroke={COLORS[3]} />
                    <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="lpEthPrice" name="LP ETH-USDC" stroke={COLORS[4]} />
                  </LineChart>
                </ResponsiveContainer>
                <div className="chart-description">
                  <p>
                    <span style={{color: COLORS[3]}}>VLP with fees</span> is based on VLP share of fees received and excluding esVMX rewards<br/>
                    <span style={{color: COLORS[2]}}>Index Price</span> is a basket 50% ETH and 50% USDC
                  </p>
                </div>
              </ChartWrapper>
            </div>
            <div className="chart-cell-V2">
              <ChartWrapper
                title="Traders Net PnL"
                loading={tradersLoading}
                data={tradersData?.data}
                csvFields={[{ key: 'pnl', name: 'Net PnL' }, { key: 'pnlCumulative', name: 'Cumulative PnL' }]}
              >
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                  <ComposedChart data={tradersData?.data} syncId="tradersId">
                    <CartesianGrid strokeDasharray="10 10" />
                    <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                    <YAxis
                      domain={[-tradersData?.stats.maxAbsCumulativePnl * 1.05, tradersData?.stats.maxAbsCumulativePnl * 1.05]}
                      orientation="right"
                      yAxisId="right"
                      tickFormatter={yaxisFormatter}
                      width={YAXIS_WIDTH}
                      tick={{ fill: COLORS[4] }}
                    />
                    <YAxis domain={[-tradersData?.stats.maxAbsPnl * 1.05, tradersData?.stats.maxAbsPnl * 1.05]} tickFormatter={yaxisFormatter} width={YAXIS_WIDTH} />
                    <Tooltip
                      formatter={tooltipFormatter}
                      labelFormatter={tooltipLabelFormatter}
                      contentStyle={{ textAlign: 'left' }}
                    />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      iconType="circle"
                      iconSize={12}
                    />
                    <Bar type="monotone" fill={mode == "dark" ? "#FFFFFF" : "#000000"} dot={false} dataKey="pnl" name="Net PnL">
                      {(tradersData?.data || []).map((item, i) => {
                        return <Cell key={`cell-${i}`} fill={item.pnl > 0 ? '#22c761' : '#f93333'} />
                      })}
                    </Bar>
                    <Line
                      type="monotone"
                      strokeWidth={2}
                      stroke={COLORS[4]}
                      dataKey="currentPnlCumulative"
                      name="Cumulative PnL"
                      yAxisId="right"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
                <div className="chart-description">
                  <p>Considers settled (closed) positions</p>
                  <p>Fees are not factored into PnL</p>
                </div>
              </ChartWrapper>
            </div>
            <div className="chart-cell-V2">
              <TradersProfitLossChart
                  syncId="tradersId"
                  loading={tradersLoading}
                  tradersData={tradersData}
                  yaxisWidth={YAXIS_WIDTH}
                  chartHeight={CHART_HEIGHT}
                />
            </div>
            <div className="chart-cell-V2">
              <GenericChart
                  loading={tradersLoading}
                  title="Open Interest"
                  data={tradersData?.data.map(item => ({ all: item.openInterest, ...item }))}
                  controls={{
                    convertToPercents: convertToPercents
                  }}
                  yaxisDataKey="all"
                  items={[{ key: 'shortOpenInterest', name: 'Short', color: RED }, { key: 'longOpenInterest', name: 'Long', color: GREEN }]}
                  type="Bar"
                />
            </div>
            <div className="chart-cell-V2">
              <GenericChart
                  loading={fundingRateLoading}
                  title="Borrowing Rate Annualized"
                  data={fundingRateData}
                  yaxisDataKey="ETH"
                  yaxisTickFormatter={yaxisFormatterPercent}
                  tooltipFormatter={tooltipFormatterPercent}
                  // TODO confirm names
                  items={[{ key: 'ETH' }, { key: 'USDC' }]}
                  type="Line"
                  yaxisDomain={[0, 45 /* ~40% is a maximum yearly borrow rate */]}
                />
            </div>
            <div className="chart-cell-V2">
              <GenericChart
                  syncId="syncGlp"
                  loading={usersLoading}
                  title="Unique Users"
                  data={usersData}
                  truncateYThreshold={6500}
                  yaxisDataKey="uniqueSum"
                  yaxisTickFormatter={yaxisFormatterNumber}
                  tooltipFormatter={tooltipFormatterNumber}
                  tooltipLabelFormatter={tooltipLabelFormatterUnits}
                  items={[
                    { key: 'uniqueSwapCount', name: 'Swaps'},
                    { key: 'uniqueMarginCount', name: 'Margin trading'},
                    { key: 'uniqueMintBurnCount', name: 'Mint & Burn VLP' }
                  ]}
                  type="Composed"
                />
            </div>
            <div className="chart-cell-V2">
              <GenericChart
                syncId="syncGlp"
                loading={usersLoading}
                title="New Users"
                data={usersData?.map(item => ({ ...item, all: item.newCount }))}
                truncateYThreshold={6000}
                yaxisDataKey="newCount"
                rightYaxisDataKey="uniqueCountCumulative"
                yaxisTickFormatter={yaxisFormatterNumber}
                tooltipFormatter={tooltipFormatterNumber}
                tooltipLabelFormatter={tooltipLabelFormatterUnits}
                items={[
                  { key: 'newSwapCount', name: 'Swap' },
                  { key: 'newMarginCount', name: 'Margin trading' },
                  { key: 'newMintBurnCount', name: 'Mint & Burn' },
                  { key: 'cumulativeNewUserCount', name: 'Cumulative', type: 'Line', yAxisId: 'right', strokeWidth: 2, color: COLORS[4] }
                ]}
                type="Composed"
              />
            </div>
            <div className="chart-cell-V2">
              <GenericChart
                loading={swapSourcesLoading}
                title="Swap Sources"
                data={swapSources}
                items={swapSourcesKeys.map(key => ({ key }))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Base;
